
import { Vue, Component } from 'vue-property-decorator';

import $app from '@/plugins/modules';
import { Common } from '@/common';
import { System } from '@/modules/system';
import { Clinic } from '@/modules/clinic'
import { IFeeHistory, IFeeHistoryFilter, IFeeHistorySearch } from '../types';
import { ITableHeader, IPagination } from '../../system/types';
import { Access } from '@/common/enums';

@Component
export default class ResearchesView extends Vue {
  common = $app.module(Common);
  system = $app.module(System);
  clinic = $app.module(Clinic);

  loading = false;

  filter: IFeeHistoryFilter = $app.clone(this.clinic.$store.FeeHistories.filter);

  showItem = false;
  processItem = false;
  selectedItem: IFeeHistory | null = null;

  get headers(): Array<ITableHeader> {
    return [
      {
        text: $app.i18n('system.Date'),
        value: 'FeeHistoryDate',
        align: 'left',
        width: '10%'
      },
      {
        text: $app.i18n('dplus.visits.Edit.Doctor'),
        value: 'UserName',
        align: 'left',
        width: '15%'
      },
      {
        text: $app.i18n('dplus.customers.Edit.Manipulation'),
        value: 'ManipulationCode',
        align: 'right',
        width: '5%'
      },
      {
        text: '',
        value: 'ManipulationTypeName',
        align: 'left',
        width: '33%'
      },
      {
        text: $app.i18n('admin.security.UserFee'),
        value: 'FeeName',
        align: 'center',
        width: '10%'
      },
      {
        text: $app.i18n('dplus.reports.PatientName'),
        value: 'PatientName',
        align: 'left',
        width: '17%'
      },
      {
        text: $app.i18n('dplus.manipulations.Price'),
        value: 'ManipulationPrice',
        align: 'right',
        width: '10%'
      }
    ];
  }

  get hasFullAccess() {
    return $app.access([Access.CompanyManager])
  }

  get search(): IFeeHistorySearch {
    return this.clinic.$store.FeeHistories;
  }

  async findNext(pagination: IPagination) {
    if (!this.loading) {
      this.loading = true;
      await this.clinic.$store.findFeeHistory(pagination);
      this.loading = false;
    }
  }

  async findFirst() {
    this.loading = false;
    const request = this.system.$module.newSearch(this.clinic.$store.FeeHistories, this.filter);

    if (!this.hasFullAccess) {
      request.userId = this.system.$store.getCurrentUser.id || 0
    }

    this.clinic.$store.setFeeHistories({
      items: [],
      filter: request
    });
    this.findNext(request);
  }

  async store(item: IFeeHistory) {
    this.processItem = true;
    try {
      await this.clinic.$store.storeFeeHistory(item);
      this.showItem = false;
    } catch (err) {
      $app.pushError(err);
    }
    this.processItem = false;
  }

  async remove(item: IFeeHistory) {
    this.processItem = true;
    try {
      await this.clinic.$store.removeFeeHistory(item);
      this.showItem = false;
    } catch (err) {
      $app.pushError(err);
    }
    this.processItem = false;
  }

  async open(item: IFeeHistory) {
    if (this.hasFullAccess) {
      this.selectedItem = item;
      this.showItem = true;
    }
  }

  mounted() {
    if (this.search.filter.totals === 0) {
      this.findFirst();
    }
  }
}
